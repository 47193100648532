import React from 'react';
import PropTypes from 'prop-types';
import { TextInput as Input } from 'grommet';

const TextInput = ({ field = {}, ...rest }) => <Input {...rest} {...field} />;

TextInput.propTypes = {
  field: PropTypes.object.isRequired,
};

export default TextInput;
