import React from 'react';
import PropTypes from 'prop-types';
import { Blank } from 'grommet-icons';

const IconArrow = ({ direction, ...props }) => {
  const rotate =
    direction === 'up'
      ? undefined
      : direction === 'down'
      ? '180deg'
      : direction === 'right'
      ? '90deg'
      : direction === 'left'
      ? '270deg'
      : '0deg';
  return (
    <Blank
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      style={{
        strokeWidth: 0,
        transform: rotate ? `rotate(${rotate})` : props.style.transform,
      }}
      {...props}
    >
      <path d="M6.9932.7456l5.4209,5.4121L11.001,7.5728,7.9688,4.5457v8.7087h-2v-8.66L2.999,7.5591,1.5859,6.144Z"></path>
    </Blank>
  );
};

IconArrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'right', 'left']),
  style: PropTypes.object,
};

export default IconArrow;
