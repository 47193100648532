import React, { memo, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from '../../lib/nodash';
import { navigate } from 'gatsby';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { FormPreviousLink } from 'grommet-icons';
import {
  Box,
  Button,
  ResponsiveContext,
  Text,
  Form,
  Keyboard,
  Heading,
} from 'grommet';
import { Formik, Field, ErrorMessage } from 'formik';
import InputText from '../Inputs/InputText';
import IconArrow from '../Icons/IconArrow';
import SmartLink from '../SmartLink';
import { CHECK_MEMBER_USER_STATUS } from '../../queries/industryQueries';

import Loading from '../Loading';
import isValidEmailAddress from '../../lib/isValidEmailAddress';
import {
  handleLogin as reduxHandleLogin,
  logout as reduxLogout,
} from '../../state/industry/industrySlice';
import { setActiveModal } from '../../state/ui/uiSlice';

import { LOGIN } from '../../queries/industryQueries';

const FormLoginIndustryMember = ({ onLogin, returnTo }) => {
  const [step, setStep] = useState('email');
  const [passwordEmailing, setPasswordEmailing] = useState(false);
  const dispatch = useDispatch();
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';
  const email = useSelector((state) => state.industry.email);

  const handleLogin = useCallback(
    (response) => {
      dispatch(reduxHandleLogin(response));
      dispatch(setActiveModal(null));
      if (returnTo) {
        navigate(
          isEqual('story', get('linktype', returnTo))
            ? `/${get('cached_url', returnTo)}`
            : returnTo
        );
      }
    },
    [reduxHandleLogin, setActiveModal, navigate, returnTo]
  );
  const logout = useCallback(() => {
    dispatch(reduxLogout());
  }, [dispatch, reduxLogout]);

  const [formError, setFormError] = useState(null);
  const [login] = useMutation(LOGIN, {});
  const [checkMemberStatus, { loading: emailLoading }] = useLazyQuery(
    CHECK_MEMBER_USER_STATUS,
    {}
  );

  return (
    <Box fill="horizontal" width={{ max: 'large' }}>
      <Formik
        initialValues={{
          email: email,
          password: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values['email'] || values['email'] === '') {
            errors['email'] = 'Required';
          } else if (!isValidEmailAddress(values['email'])) {
            errors['email'] = 'Invalid email address';
          }
          if (!values['password'] || values['password'] === '') {
            errors['password'] = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            logout();
            setFormError(null);
            const loginResponse = await login({
              variables: {
                input: {
                  identifier: values.email,
                  password: values.password,
                  provider: 'local',
                },
              },
            });
            handleLogin(loginResponse);
            if (onLogin) onLogin();
          } catch (e) {
            setSubmitting(false);
            setFieldError('password', 'Invalid email/password');
          }
        }}
      >
        {({ isSubmitting, handleSubmit, isValid, values }) => {
          const handleEmailSubmission = async (email) => {
            await checkMemberStatus({
              variables: { email: email },
              onCompleted: async ({ checkMemberUserStatus }) => {
                if (
                  checkMemberUserStatus.user_exists &&
                  checkMemberUserStatus.has_set_password
                ) {
                  setStep('password');
                }
                if (
                  checkMemberUserStatus.user_exists &&
                  !checkMemberUserStatus.has_set_password
                ) {
                  setPasswordEmailing(true);
                  await fetch(
                    process.env.GATSBY_BACKDROP_API_FORGOT_PASSWORD_URL,
                    {
                      method: 'POST',
                      mode: 'cors',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        email: values.email,
                      }),
                    }
                  );
                  setStep('passwordNotSet');
                }
                if (!checkMemberUserStatus.user_exists) {
                  setStep('prompt');
                }
              },
            });
          };

          return (
            <Form id="login_form" onSubmit={handleSubmit} fill>
              <Box
                direction="row-responsive"
                gap="large"
                className="row"
                fill="horizontal"
                justify="between"
              >
                <Box flex={true} gap={isMobile ? 'medium' : 'medium'}>
                  {step === 'email' && (
                    <Keyboard
                      onEnter={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleEmailSubmission(values.email);
                      }}
                    >
                      <Box gap="medium">
                        <Box className="line-item-property__field">
                          <label htmlFor="first_name">Email</label>
                          <Field
                            name="email"
                            id="email"
                            component={InputText}
                            autoFocus={true}
                          />
                          <ErrorMessage name="email">
                            {(msg) => (
                              <Text
                                color="status-error"
                                style={{ textAlign: 'left' }}
                              >
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </Box>
                        <Box align="center" justify="center">
                          <Button
                            align="center"
                            primary
                            flex={false}
                            type="button"
                            disabled={!isValidEmailAddress(values.email)}
                            label="Next"
                            icon={
                              emailLoading || passwordEmailing ? (
                                <Loading />
                              ) : (
                                <IconArrow direction="right" size="small" />
                              )
                            }
                            onClick={() => handleEmailSubmission(values.email)}
                            reverse
                            fill
                          />
                        </Box>
                      </Box>
                    </Keyboard>
                  )}

                  {step === 'password' && (
                    <Keyboard onEnter={handleSubmit}>
                      <Box gap="medium">
                        <Box className="line-item-property__field">
                          <label htmlFor="first_name">Password</label>
                          <Field
                            name="password"
                            id="password"
                            component={InputText}
                            type="password"
                            autoFocus={true}
                          />
                          <ErrorMessage name="password">
                            {(msg) => (
                              <Text
                                color="status-error"
                                style={{ textAlign: 'left' }}
                              >
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </Box>
                        <Box>
                          <Button
                            primary
                            type="submit"
                            disabled={isSubmitting || !isValid}
                            label="Login"
                            icon={isSubmitting ? <Loading /> : null}
                            reverse
                            fill
                          />
                        </Box>
                      </Box>
                    </Keyboard>
                  )}

                  {step === 'passwordNotSet' && (
                    <Box pad="large" align="center">
                      <Heading textAlign="center" level={3}>
                        No password set
                      </Heading>
                      <Text textAlign="center">
                        It looks like you have not set a password yet. Please{' '}
                        <Text weight={500}>check your email</Text> for a link to
                        set your password.
                      </Text>
                    </Box>
                  )}

                  {step === 'prompt' && (
                    <Box pad="large" align="center" gap="medium">
                      <Heading
                        textAlign="center"
                        level={4}
                        style={{ textTransform: 'none' }}
                      >
                        No account
                      </Heading>
                      <Text textAlign="center">
                        An account with this email does not exist. <br />
                        Apply for Industry by Backdrop to activate trade
                        benefits.
                      </Text>
                      <Box direction="row-responsive" gap="small">
                        <Button
                          label="Back"
                          icon={<FormPreviousLink />}
                          onClick={() => setStep('email')}
                        />
                        <SmartLink
                          as={Button}
                          primary
                          label="Apply Now"
                          to="https://industrysignup.backdrophome.com/signup/"
                          target="_blank"
                        />
                      </Box>
                    </Box>
                  )}

                  {formError && (
                    <Box pad="medium">
                      <Text color="status-critical">{formError}</Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormLoginIndustryMember.propTypes = {
  onForgot: PropTypes.func,
  title: PropTypes.string,
  onLogin: PropTypes.func,
  returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(FormLoginIndustryMember);
